@import "./Custome.scss";
@import "./Font.scss";
@import "./theme.scss";
@media (max-width: 567px) {
  .roles-resp-tab {
    .tab-list {
      width: 40px !important;
      height: 40px !important;
      padding: 7px !important;
      margin: 11px 8px !important;
      .tabIcon {
        width: 17px;
      }
      p {
        display: none;
      }
    }
  }
}
@media (max-width: 767px) {
  .left-login {
    display: none !important;
  }
  .level-btn {
    &::after {
      left: 20px;
    }
    &::before {
      width: 20px;
      left: 20px;
    }
    .img-box {
      width: 22px;
      height: 22px;
    }
  }
  .level-master {
    .line-space {
      width: 30px;
    }
  }
  .invoice-res {
    width: 77.333333% !important;
  }
}
@media (min-width: 768px) {
  .invoice-res {
    width: 72% !important;
    margin-left: 16rem !important;
  }
}

@media (max-width: 991px) {
  .finance-box {
    width: 350px;
  }
}
@media (min-width: 992px) {
  .body-design {
    margin-left: 250px;
  }
  .finance-box {
    width: 500px;
  }

  .invoice-res {
    width: 75% !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .login-box {
    width: 320px;
  }
  .login-logo-box {
    width: 320px;
  }
  .roles-resp-tab.tab-list {
    // width: 150px !important;
    flex: 1 0;
    p {
      display: none;
    }
  }
  .roles-resp-tab {
    .tab-list {
      width: 110px;
    }
  }
}

@media (min-width: 1199px) and (max-width: 1399px) {
  // naren start
  .boq-cont-res {
    width: 80%;
  }
  .login-logo-box {
    width: 60%;
  }
  // naren end
  .login-box {
    width: 60%;
  }

  .roles-resp-tab {
    .tab-list {
      width: 110px !important;
    }
  }
}
@media (min-width: 1200px) {
  .emply-master-tab .tab-list {
    flex: 1 0;
  }
}

@media (min-width: 1400px) {
  .boq-cont-res {
    width: 85%;
  }
  .login-box {
    width: 50%;
  }
  .login-logo-box {
    width: 50%;
  }
  .roles-resp-tab {
    .tab-list {
      width: 110px;
    }
  }
  .newpage {
    height: 130px;
  }
  .newpage1 {
    height: 95px;
  }
}
