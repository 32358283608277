@font-face {
  font-family: "f1";
  src: url("../fonts/Euclid Circular/Euclid Circular A Regular.ttf");
}
@font-face {
  font-family: "f2";
  src: url("../fonts/Euclid Circular/Euclid Circular A Medium.ttf");
}

@font-face {
  font-family: "f3";
  src: url("../fonts/Euclid Circular/Euclid Circular A SemiBold.ttf");
}

@font-face {
  font-family: "f4";
  src: url("../fonts/Euclid Circular/Euclid Circular A Bold.ttf");
}

.fs-20 {
  font-size: 20px;
}
.fs-40 {
  font-size: 40px;
}
